import http from "../http-common";

class ChapterService {
  getChapter() {
    var token = window.sessionStorage.getItem("token");
    return http.get("/chapters", {params: {token: token}});
  }

}

export default new ChapterService();
