import { Component } from "react";
import { withRouter } from "../common/with-router";

class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = { dummy: 0 };
  }

  componentDidMount() {
    this.setState({ dummy: 1 });
  }

  componentDidUpdate() {
    localStorage.clear();
    sessionStorage.clear()
    this.props.loggedIn({
      token: null,
      role: null,
      name: null,
      chapterRole: null,
    });
    this.props.router.navigate("/");
  }

  render() {
    return "";
  }
}


export default withRouter(Logout);