import React, { Component } from "react";
import AccountDataService from "../services/account.service";
import { withRouter } from "../common/with-router";
import { Link } from "react-router-dom";
import "../App.css"

class Login extends Component {
  constructor(props) {
    super(props);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.login = this.login.bind(this);
    this.formRef = React.createRef();

    this.state = {
      email: "",
      password: "",
      errorMessage: null,
    };

    localStorage.clear();
    sessionStorage.clear()
    this.props.loggedIn({
      token: null,
      role: null,
      name: null,
      chapterRole: null,
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
      errorMessage: null,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
      errorMessage: null,
    });
  }

  login() {
    const node = this.formRef.current;
    node.classList.add('was-validated');
    if (!node.checkValidity()) {
      return;
    }
    var data = {
      email: this.state.email,
      password: this.state.password
    };
    console.log(data);
    window.sessionStorage.setItem("email", data.email);
    AccountDataService.login(data)
      .then(response => {
        this.props.loggedIn(response.data);

        // Disable till logged in
        const buttonElements = document.querySelectorAll("button");
        buttonElements.forEach(button => {
          button.disabled = true;
        });

        var snackbar = document.getElementById("snackbar");
        snackbar.className = "show";
        setTimeout(function () { snackbar.className = snackbar.className.replace("show", ""); }, 3000);
        setTimeout(() => {
          this.props.router.navigate(response.data.profile ? "/signups" : "/profile");
        }, 1000);
      })
      .catch(e => {
        console.log(e.response.status);
        console.log("Error message:");
        console.log(e.response.data.message);
        this.setState({ errorMessage: e.response.data.message });
        console.log(this.state);
        // node.classList.remove('was-validated');
      });
  }

  render() {
    return (
      <>
        <div className="center-center fancy-box">
          <h1 className="mb-3">Login</h1>
          <form className="needs-validation" noValidate ref={this.formRef}>
            <div className="mb-3">
              <input type="email" className="form-control" id="loginEmail" placeholder="Email Address" required value={this.state.email} onChange={this.onChangeEmail} name="email" aria-describedby="emailHelp" />
              <div className="invalid-feedback">Please provide a valid email address</div>
            </div>
            <div className="mb-3">
              <input type="password" className="form-control" id="loginPassword" placeholder="Password" required minLength="8" value={this.state.password} onChange={this.onChangePassword} name="password" />
              <div className="invalid-feedback">Please provide a password with 8 or more characters</div>
            </div>
            {this.state.errorMessage !== "Verify your email" && (<div className="mb-3 text-danger">{this.state.errorMessage}</div>)}
            {this.state.errorMessage === "Verify your email" && (<div className="mb-3 text-danger">You email has not been verified yet. <Link to="/verify-email">Verify your email</Link> now.</div>)}
            <button type="button" className="btn btn-primary green-button" onClick={this.login}>Submit</button>
            <div className="bottom-text">
              Need an account? <Link to={"/register"}>Register</Link><br/>
              <Link to={"/forgot-password"}>Forgot your password?</Link>
            </div>
          </form>
        </div>
        <div id="snackbar">Logging in...</div>
      </>
    );
  }
}

export default withRouter(Login);