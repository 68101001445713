import React, { Component } from "react";
import { withRouter } from "../common/with-router";
import ChapterService from "../services/chapter.service";

class Chapter extends Component {
  constructor(props) {
    super(props);
    this.handleError = this.handleError.bind(this);
    this.showLeaders = this.showLeaders.bind(this);
    this.showVolunteers = this.showVolunteers.bind(this);
    this.showAttendance = this.showAttendance.bind(this);
    this.state = {};
    ChapterService.getChapter()
    .then(resp => {
      console.log(resp.data);
      this.setState({
        name: resp.data.name,
        tab: "leaders",
        leaders: resp.data.leaders,
        volunteers: resp.data.volunteers,
        attendances: resp.data.attendances,
      });
    }).catch(this.handleError);
  }

  handleError(e) {
    if (e.response?.status === 403) {
      this.props.router.navigate("/login");
    } else {
      console.log(e);
    }
  }

  showLeaders(e) {
    this.setState({tab: "leaders"});
  }

  showVolunteers(e) {
    this.setState({tab: "volunteers"});
  }

  showAttendance(e) {
    this.setState({tab: "attendance"});
  }

  getLeaderName(chapterRole) {
    var result = "";
    this.state.leaders.map(leader => {
      if (leader.chapterRole === chapterRole) {
        result = leader.firstName + " " + leader.lastName;
      }
      return null;
    });
    return result;
  }

  render() {
    return (
      <div className="fancy-box">
        <h1 className="mb-3">Chapter Mgmt{this.state.name && (<span> - {this.state.name}</span>)}</h1>
        <ul className="nav nav-tabs mb-3">
          <li className="nav-item">
            <button className={"nav-link" + (this.state.tab === "leaders" ? " active" : "")} aria-current="page" onClick={this.showLeaders}>Leaders</button>
          </li>
          <li className="nav-item">
            <button className={"nav-link" + (this.state.tab === "volunteers" ? " active" : "")} aria-current="page" onClick={this.showVolunteers}>Volunteers</button>
          </li>
          <li className="nav-item">
            <button className={"nav-link" + (this.state.tab === "attendance" ? " active" : "")} aria-current="page" onClick={this.showAttendance}>Attendance</button>
          </li>
        </ul>
        {this.state.tab === "leaders" && (
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {["President", "Vice President", "Tresurer", "Secretery"].map(chapterRole => (
                  <tr>
                    <td>{chapterRole}</td>
                    <td>{this.getLeaderName(chapterRole)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {this.state.tab === "volunteers" && (
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Grade</th>
                </tr>
              </thead>
              <tbody>
                {this.state.volunteers?.map(volunteer => (
                  <tr>
                    <td>{volunteer.firstName} {volunteer.lastName}</td>
                    <td>{volunteer.gradeLevel}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {this.state.tab === "attendance" && (
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Hours</th>
                  <th>Sessions</th>
                </tr>
              </thead>
              <tbody>
                {this.state.attendances?.map(attendance => (
                  <tr>
                    <td>{attendance.firstName} {attendance.lastName}</td>
                    <td>{attendance.hours}</td>
                    <td>{attendance.sessions}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }

}

export default withRouter(Chapter);
